import { Button, Dialog, Tooltip} from '@trioptima/trids-react';
import {envValue, isNullOrUndefined, redirectToSSOLogin} from '../../../InnerFunctions';
import './Card.css';
import { useEffect, useRef, useState } from 'react';
import {redirectionPropsType} from "../../../propsType";

const Card = (props: {
  userId: string;
  customTargetRedirectURL: string | undefined;
  appName: string;
  organization: string;
  urlAppId: string;
}) => {
  const { userId, appName, organization, urlAppId } = props;
  const [envVal, setEnvVal] = useState<string>('');
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(60);
  const formPostRef = useRef(null);
  const maxUserIdLength: number = 20;
  const[userRedirection,setUserRedirection] = useState<redirectionPropsType>();
  let redirectionData : redirectionPropsType;

  useEffect(() => {
    envValue(setEnvVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectByCardPost = async (event: any) => {
    event.preventDefault();
    const response = await fetch(`/sso/api/v1/legacyusers/validate?appId=${urlAppId}&userId=${userId}`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Access-Control-Request-Headers': 'Location',
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      redirectionData = await response.json();
      //setting the custom URL if the user is login through a redirect or else the home page url of the app
      setUserRedirection(redirectionData);
      setTimeout(()=>
      // @ts-ignore
      {formPostRef.current.submit()},1000);
      setIsVisible(false);
    }else if (response.status === 409){
      window.open("/redirectionError","_blank");
    }else {
      setIsVisible(true);
    }
  };

  if (isVisible === true) {
    setTimeout(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);
    if (count === 0) {
      redirectToSSOLogin();
      setCount(0);
    }
  }

  return (
    <>
      <Dialog
        isOpen={isVisible}
        header={`Your session will be refreshed in ${count} seconds`}
        footer={
          <Button
            onClick={() => {
              redirectToSSOLogin();
            }}
          >
            Refresh Now
          </Button>
        }
      >
        <p aria-label="Session out warning"></p>
      </Dialog>

      <div className="t-col-20 t-block">
        <div className="card_userCard t-p-l t-text-left" data-testid="card_userCard" aria-label={`Card for ${userId}`}>
          <div className="t-pb-l">
              {userId.length > 20 ?
                  <Tooltip message={userId}><h3>{userId.substring(0, maxUserIdLength)}...</h3></Tooltip>
                  : <h3>{userId}</h3>
              }
            <h5>User</h5>
          </div>
          <div className="t-pb-l">
            <h3>{organization}</h3>
            <h5>Organisation</h5>
          </div>
          <div className="t-author t-flex">
              <form
                action={userRedirection?.appUrl}
                method="POST"
                target={`_${appName}-${envVal}`}
                ref={formPostRef}
                onSubmit={redirectByCardPost}
              >
                <input type="hidden" name="user" id="user" value={userId} />
                <input type={'text'} hidden={true} name="redirectURL" value={isNullOrUndefined(props.customTargetRedirectURL) ? "" : props.customTargetRedirectURL}/>
                <input type="hidden" name="accessToken" id="accessToken" value={userRedirection?.accessToken} />
                <button
                  type="submit"
                  className="t-btn t-text-teal t-px-s t-mr-s"
                  aria-label={`Launch the app with ${userId}`}
                >
                  <i className="t-icon t-icon-external-link"></i>
                  Launch
                </button>
              </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
