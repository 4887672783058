import Card from '../Card/Card';
import ShowMore from '../../Atoms/ShowMore/ShowMore';
import {Loading} from '@trioptima/trids-react';
import cardPropsType, {appUsersPropsType} from '../../../propsType';
import Search from '../../Molecules/Search/Search';

const CardSession = (props: {
    userMapping: cardPropsType;
    customTargetRedirectURL : string | undefined;
    searchData: appUsersPropsType[];
    appUsers: appUsersPropsType[] | null;
    searchFunData: Function;
    urlAppId: string;
}) => {
    return (
        <>
            {Array.isArray(props.userMapping.appUsers) && props.userMapping.appUsers.length > 1 ? (
                <>
                    <Search appUsers={props.appUsers} searchFunData={props.searchFunData}/>
                    <section>
                        <div className="card">
                            <div className="t-grid storybook-grid-demo">
                                {Array.isArray(props.searchData) && props.searchData ? (
                                    props.searchData.map((items: appUsersPropsType, index: number) => {
                                        return (
                                            <Card
                                                userId={items.userId}
                                                customTargetRedirectURL={props.customTargetRedirectURL}
                                                appName={props.userMapping.appName}
                                                organization={items.organization}
                                                key={index}
                                                urlAppId={props.urlAppId}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className="searchErrorMsg t-p-l t-text-left">
                                        <h2>Sorry, we couldn’t find any matches. </h2>
                                        <p>Please try to change your keyword.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <ShowMore userMapLength={props.userMapping.appUsers.length}/>
                    </section>
                </>
            ) : (
                <div className="LoadingText">
                    <h1 className="t-mt-e">Please wait it's processing...</h1>
                    <Loading size={'big'}/>
                </div>
            )}
        </>
    );
};

export default CardSession;
