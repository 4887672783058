import { CurrentYear } from './InnerFunctions';
import { browserName, browserVersion } from 'react-device-detect';

const ErrorPage = (props: { errorMsgScreen?: string, responseCode?: string}) => {
  return (
    <div className="t-error-page">
      <div className="t-error-container">
        {(props.responseCode ==='200'|| props.responseCode ==='409')? <h1>Oops!!</h1>:<h1>Something went wrong</h1>}
        {(props.errorMsgScreen === undefined || props.errorMsgScreen===null)? (
            <><p>Due to a server error, we couldn’t find the page you’re looking for. </p>
              <p>
                The error is probably temporary, so please wait a few seconds and then refresh the page. If the issue persists
                after several minutes, please contact.
              </p>
              <button className="t-btn t-primary t-minimal ">
                <a href="https://sso.osttra.com/" style={{ color: '#464646', textDecoration: 'none' }}>
                  Re-Login
                </a>
              </button>
            </>
        ) : (
            <h3>{props.errorMsgScreen}</h3>
        )
        }
        <h2 style={{ lineHeight: '20px' }}>
          If you think you should be able to access this page, contact Osttra Support with error details like below:-
        </h2>
        <ul className="t-list">
          <li>
            Find Osttra Support contacts here - <a href="https://osttra.com/support/">https://osttra.com/support/</a>
          </li>
        </ul>
        <></>
        <h2>Status :- </h2>
        <dl className="t-error-container__meta-data" aria-label="meta-data">
          <dt>Response code </dt>
          <dd>{props.responseCode}</dd>
          <dt>Time </dt>
          <dd>{new Date().toUTCString()}</dd>
          <dt>URL</dt>
          <dd>https://sso.osttra.com</dd>
          <dt>Browser information</dt>
          <dd>{browserName+" "+browserVersion}</dd>
        </dl>

        <footer style={{ marginTop: '25px', minHeight: '2.375rem' }}>
          © {CurrentYear} OSTTRA Group. Proprietary and Confidential.{' '}
          <a href="https://osttra.com/disclaimer/" target="_disclaimer">
            Disclaimer.
          </a>
        </footer>
      </div>
    </div>
  );
};

export default ErrorPage;
